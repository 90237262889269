import React from "react";
import Layout from "../../../Layout";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../page-style.scss";

const data = [
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/4-aug-1/pic1.jpeg",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/4-aug-1/pic2.jpeg",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/4-aug-1/pic3.jpeg",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/4-aug-1/pic4.jpeg",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/4-aug-1/pic5.jpeg",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/4-aug-1/pic6.jpeg",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/4-aug-1/pic7.jpeg",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/4-aug-1/pic8.jpeg",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/4-aug-1/pic9.jpeg",
];

export default function FriendshipDay() {
  const settings = {
    infinite: true,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  function ImageSlider() {
    return (
      <div className="calendar-page__slider">
        <Slider {...settings}>
          {data.map((item, i) => (
            <div className="calendar-page__images__slide">
              <div className="calendar-page__images__card">
                <img alt="" src={item}></img>
              </div>
              <br />
              <center>
                <h3>{item.titleSubHead}</h3>
              </center>
            </div>
          ))}
        </Slider>
      </div>
    );
  }

  return (
    <Layout>
      {/* <SEO
        title="School Gallery | Chinmaya Vidyalaya Vasant Vihar"
        description="Explore photos and videos of the campus and student activities  at the Vidyalaya for a holistic view of student life."
      /> */}

      <div className="calendar-page">
        <div className="goback">
          <a href="/school-events/">
            <i className="fa-solid fa-arrow-left"></i>Go Back
          </a>
        </div>
        <h1 className="heading">Friendship Day</h1>

        <p>
          <strong>
            ”FRIENDS ARE THE PEOPLE WHO MAKE YOU SMILE BRIGHTER, LAUGH LOUDER
            AND LIVE BETTER.”
          </strong>
          <br />
          <br />
          Friendship day was celebrated on 26th July 2023 by the pre - primary
          learners of Chinmaya Vidyalaya, New Delhi. The day is meant to
          celebrate meaningful relationships formed with our peers. Keeping this
          in mind our kindergarteners sang the friendship song , created a
          friendship craft and bought friendship bands to give to their friends.
          The whole day was aimed at strengthening socio - emotional
          development,communication, cooperation, sharing and empathy amongst
          the tiny- tots. The young ones relished quality time spent with peers.
        </p>
        <section className="calendar-page__videosection">
          <ImageSlider />
        </section>
      </div>
    </Layout>
  );
}
